import React, { useEffect, useState, useContext } from "react";
import Service from "./service";
import TherapistJson from "./therapists.JSON";
import Anesthesia from "../assets/services/Anesthesia.jpeg";
import Ayurveda from "../assets/services/Ayurveda.jpeg";
import CashlesssHealthIns from "../assets/services/CashlesssHealthIns.jpeg";
import jr from "../assets/services/jr.jpeg";
import Neuro from "../assets/services/Neuro.jpeg";
import Onco from "../assets/services/Onco.jpeg";
import Ortho from "../assets/services/Ortho.jpeg";
import ENT from "../assets/services/ENT.png";
import Pediatric from "../assets/services/Pediatric.jpeg";
import Piles from "../assets/services/Piles.jpeg";
import URO from "../assets/services/URO.jpeg";
import Gyne from "../assets/services/gynaec.jpg";
import ServicePopUp from "./servicesPopUp";
import { useActions } from "../Actions";
import { store } from "../Store";
import plusIcon from "../assets/plusIcon.svg";
import LogoNew from "../assets/images/maulihospital.png";
import { Helmet } from "react-helmet";

const Services = () => {
  const [therapists, setTherapists] = useState([]);
  const { state, dispatch } = useContext(store);
  const actions = useActions(state, dispatch);
  const { togglePopup, selectedServiceData } = state;

  let therapistData = [
    {
      id: 1,
      image: Piles,
      name: "Piles Surgery",
      description:
        "<ul class='ml-50'><li style='list-style:disc;'>Hemorrhoidectomy or piles removal surgery is the medical term for the surgical elimination of hemorrhoids. Piles located in the upper two-thirds of the anal canal or of grades 1 and 2 can be treated via non-surgical methods.</li><br/><li style='list-style:disc;'>However, those located in the lower third part or of grades 3 and 4, need surgery under anesthetics, due to the presence of so many pain-sensing neurons in the region that can cause severe discomfort to the patient.Your doctor can also perform laser procedures, rubber band ligation, sclerotherapy, infrared coagulation, etc to diminish or remove them.</li><br/> <li style='list-style:disc;'>To remove the piles, the doctor performs a series of little cuts around the anus with the administration of local anesthesia, which causes the area to be operated on to become numb while you remain awake or using general anesthesia that will put you to sleep.</li><br/><li style='list-style:disc;'>Surgical processes are often advised for the complete treatment of bleeding hemorrhoids because it accompanies many benefits. Some of them are- <br/>Reliving recurring <br/>Less bleeding<br/>Treats easily<br/>Speedy recovery<br/>Safer process<br/>      </li><br/></ul>",
    },
    {
      id: 2,
      image:
        jr,
      name: "Joint Replacement",
      description: `<ul class='ml-50'><li style='list-style:disc;'>Joint replacement surgery removes damaged or diseased parts of a joint. It replaces them with new, human-made parts. Joints may need to be replaced when they are damaged from:<br/>
        Arthritis (inflammation of joints that causes pain and stiffness)<br/>
        Years of use<br/>
        Disease<br/>
        </li><br/> 
        <li style='list-style:disc;'>The goals of joint replacement surgery are to relieve pain, help the joint work better, and improve walking and other movements. Your provider will likely first suggest other treatments to reduce pain and help you move better, such as:<br/>
        An exercise program<br/>
        Walking aids like a cane or walker<br/>
        Physical therapy<br/>
        Medications</li><br/> </ul>`,
    },
    {
      id: 3,
      image: Onco,
      name: "Onco Surgery",
      description: `<ul class='ml-50'><li style='list-style:disc;'>Surgical Oncology is a discipline of medicine committed to treating cancer tumours through the means of surgery. Cancer treatment methods include chemotherapy, radiation therapy, surgery, hormone therapy, bone marrow transplant, immunotherapy, targeted drug therapy and more.
      </li><br/> <li style='list-style:disc;'>Traditionally, surgery has been the first line of treatment for cancer. It’s only in the last century that other treatment avenues like chemotherapy and radiotherapy came into being. Surgical oncology deals with the use of surgical techniques to treat all forms of cancer.
      </li><br/> <li style='list-style:disc;'>Cancer surgery may not necessarily be the only treatment available for cancer but is generally considered for most forms of cancer. An oncologist would have to consider surgery along with other treatments like chemotherapy and radiotherapy.
      </li><br/>
      <li style='list-style:disc;'>Surgical oncology involves the following functions:<br/>
      Diagnosis of cancer and determining its stage by taking a biopsy or other methods.<br/>
      Surgically removing the tumor or a part of it.<br/>
      Surgically remove the tumor along with other affected body parts.<br/>
      Reconstruct parts that are affected due to the surgical treatment.<br/>
      </li><br/></ul>`,
    },
    {
      id: 4,
      image:URO,
      name: "Uro Surgery",
      description: `<ul class='ml-50'><li style='list-style:disc;'>Is used to help manage a health condition or enhance wellness</li><br/> <li style='list-style:disc;'>Is used to help manage a health condition or enhance wellness</li><br/> <li style='list-style:disc;'>Is used to help manage a health condition or enhance wellness</li><br/></ul>`,
    },
    {
      id: 5,
      name: "Neuro Surgery",
      image:Neuro,
      description: `<ul class='ml-50'><li style='list-style:disc;'>Most people think of neurosurgery as brain surgery — but it is much more!<br/>
      It is the medical specialty concerned with the diagnosis and treatment of of patients with injury to, or diseases/disorders of the brain, spinal cord and spinal column, and peripheral nerves within all parts of the body. The specialty of neurosurgical care includes both adult and pediatric patients. Dependent upon the nature of the injury or disease a neurological surgeon may provide surgical and/or non-surgical care.
      
      </li><br/> <li style='list-style:disc;'>Neurosurgeons provide the operative and non-operative management (i.e., prevention, diagnosis, evaluation, treatment, critical care and rehabilitation) of neurological disorders. Because neurosurgeons have extensive training in the diagnosis of all neurological disease, emergency room doctors, neurologists, internists, family practitioners, and osteopaths often call upon them for consultations.</li><br/> </ul>`,
    },
    {
      id: 6,
      name: "Otrhopadic Surgery",
      image: Ortho,
      description: `<ul class='ml-50'><li style='list-style:disc;'>Orthopedics focuses on treating the musculoskeletal system. This system comprises muscles, bones, joints, ligaments, and tendons. A person who specializes in orthopedics is known as an orthopedist.</li><br/> <li style='list-style:disc;'>Orthopedists use surgical and nonsurgical approaches to treat musculoskeletal issues, such as sports injuries, joint pain, and back problems.
      </li><br/> <li style='list-style:disc;'>Orthopedics, also known as orthopedic surgery, is a branch of medicine that focuses on the care of the skeletal system and its interconnecting parts. These parts include the following:<br/>
      bones<br/>
      muscles<br/>
      joints<br/>
      tendons<br/>
      ligaments<br/>
      nerves<br/>
      </li><br/> <li style='list-style:disc;'>There are generally two types of orthopedists: surgical and nonsurgical. The former are called orthopedic surgeons, while nonsurgical orthopedists include physiatrists and physical medicine and rehabilitation specialists.

      </li><br/></ul>`,
    },
    {
      id: 7,
      image: Anesthesia,
      name: "Anesthetist",
      description: `<ul class='ml-50'><li style='list-style:disc;'>Anesthesiologists are the doctors trained to administer and manage anesthesia given during a surgical procedure.</li><br/> <li style='list-style:disc;'>They are also responsible for managing and treating changes in your critical life functions--breathing, heart rate, and blood pressure--as they are affected by the surgery being performed.</li><br/> <li style='list-style:disc;'>Anesthesia refers to the process of controlling pain and other sensations during surgeries or any medical procedures by using medicines known as anesthetics. Anesthetics are either given through injections or are inhaled. This medicine is used for blocking pain and making a person unconscious during surgery, etc.
      </li><br/></ul>`,
    },
    {
      id: 8,
      image: Gyne,
      name: "Gynaecologist",
      description: `<ul class='ml-50'><li style='list-style:disc;'>Gynecological surgery refers to surgery on the female reproductive system usually performed by gynecologists. It includes procedures for benign conditions, cancer, infertility, and incontinence.[1] Gynecological surgery may occasionally be performed for optional or cosmetic purposes, such as hymenoplasty or labiaplasty.

            </li><br/> <li style='list-style:disc;'>Following are different types of Gynecologic Procedures-<br/>
            Cervical Cryosurgery<br/>
            Colposcopy<br/>
            Dilation and Curettage (D&C)<br/>
            Hysteroscopy<br/>
            LEEP Procedure<br/>
            Pelvic Laparoscopy</li><br/> <li style='list-style:disc;'>Gynecological surgery includes:<br/>
            Removal of ovarian cyst<br/>
            surgical contraception, and<br/>
            Hysterectomy</li><br/></ul>`,
    },
    {
      id: 9,
      image: ENT,
      name: "ENT Surgery",
      description: `<ul class='ml-50'><li style='list-style:disc;'>ENT (“Ear, Nose, Throat”) surgery is a medical specialty practiced by ENT surgeons. </li><br/> <li style='list-style:disc;'>‘Otolaryngologists’, as they are referred to in the medical community, can be a bit of a tongue twister for the lay person, which is why they are better known as ENT specialists. 
      
      </li><br/> <li style='list-style:disc;'>The term is also self-explanatory, as it derives from their area of expertise – the ear, nose and throat. </li><br/>
      <li style='list-style:disc;'>This includes related structures like the sinuses and larynx. While general physicians may be able to treat problems that affect these areas, they can only offer medical treatment. ENT specialists are physicians trained in both the medical and surgical management of such health conditions, which is why they are also referred to as Head and Neck Surgeons.</li><br/></ul>`,
    },
    {
      id: 10,
      image: Pediatric,
      name: "Pediatric Surgery",
      description: `<ul class='ml-50'><li style='list-style:disc;'>Pediatric surgery is a subspecialty of surgery involving the surgery of fetuses, infants, children, adolescents, and young adults. </li><br/>
       <li style='list-style:disc;'>Pediatric surgery arose in the middle of the 1879 century as the surgical care of birth defects required novel techniques and methods, and became more commonly based at children's hospitals.</li><br/> 
       </ul>`,
    },
    {
      id: 11,
      name: "Ayurveda",
      image: Ayurveda,
      description: `<ul class='ml-50'><li style='list-style:disc;'>There are two branches of surgery in Ayurveda — Shalya Tantra, which refers to general surgery, and Shalakya Tantra which pertains to surgeries related to the eyes, ears, nose, throat and teeth.</li><br/><li style='list-style:disc;'> All postgraduate students of Ayurveda have to study these courses, and some go on to specialise in these, and become Ayurveda surgeons.</li><br/></ul>`,
    },
    {
      id: 11,
      name: "Cashlesss Health Ins",
      image: CashlesssHealthIns,
      description: `<ul class='ml-50'><li style='list-style:disc;'>OUR TPA/INSURANCE PRTETNERS<br/>
      Star Insurance <br/>
      Care Health <br/>
      NVGT Care <br/>
      SBI General Insurance <br/>
      Universal Sampo <br/>
      TATA AIG <br/></li><br/></ul>`,
    },
  ];
  return (
    <>
    <Helmet>
    <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
    <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
    </Helmet>
    <div className="w-80 mx-auto flex md:flex-col flex-col  mb-100 mt-20">
     <div className="flex flex-row xs:flex-col sm:flex-col md:flex-col justify-between w-full mx-auto mb-50 ">
							<div className="float-left mr-40 mt-10 lg:w-70% xl:w-40% 2xl:w-40%">
								<div className="heading primary-heading inner-heading">
									<div className="title-holder flex flex-row w-100% ">
										<div className="title-block mr-0 xs:w-100% sm:w-100% md:w-100% w-100% lg:mr-20  xl:mr-20 2xl:mr-20">
											<h3 className="title xs:text-12 text-16 text-hyperlink  leadin-27 font-normal mb-10">Services</h3>
											<h1 className="subtitle xs:text-16 sm:text-16 md:text-20 text-40 text-black leading-27 font-bold">Our Best Services</h1>
										</div>
										<div className="inline-block xs:w-50% sm:w-50% md:w-50% w-100% relative">
											<img className='xs:h-80 sm:h-100% md:h-100% h-50% absolute bottom-0' src={LogoNew} alt="Mauli Hospital Logo HD"/>
										</div>
									</div>
								</div>
							</div>
							<div className="float-right mt-50 w-full">
								<div className="heading-description ">
									<p className="text-text-desc xs:text-sm sm:text-sm md:text-sm text-md text-justify">At Mauli Hospital And Research Center Satara, we provide best of the services in affordable price. At Maulit hospital we have done many of the critical surgeries succcessfully.
                  Also, we support those people who can't do the highest prices surgery, we provide them best of health care services. This metric measures the ability of a hospital to provide quality care for its patients, 
                  without patients developing infections, developing bed sores, reacting to transfusions, postoperative respiratory failure, 
                  postoperative hemorrhages, postoperative sepsis, or postoperative pulmonary embolisms. <br/><br/>Also, we are providing <b className=" text-red text-20">Cashless Health Insurance</b> with many parteners as mentioned in Cashless services.
                  </p>
								</div>
							</div>
      </div>

      <div className="grid mx-auto xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 grid-cols-4  mb-5 mt-2">
        {therapistData.map((service) => (
          <div className="p-5 shadow-md my-3 mx-3 overflow-hidden rounded-md mb-80">
            <div className="overflow-hidden">
              <img
                src={service.image}
                alt={service.name}
                className="service-image overflow-hidden w-400 h-200"
              />
            </div>
            <h1 className="py-5 text-center text-20 text-blue-800 font-medium">
              {service.name}
            </h1>
            <div
              className="text-center py-2 bg-gray-100  hover:text-white hover:bg-table-green hover:cursor-pointer  p-3 rounded-md text-gray-400"
              onClick={() => {
                actions.saveSelectedService(service);
                actions.toggleServicePopUp(true);
              }}>
              View Details
            </div>
          </div>
        ))}
      </div>
      {togglePopup && (
        <ServicePopUp
          togglePopup={togglePopup}
          closeSuccessPopup={false}
          service={selectedServiceData}
        />
      )}
    </div>
    </>
  );
};
export default Services;
