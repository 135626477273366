import React, { useEffect, useState } from "react";
import Therapist from "./therapist";
import TherapistJson from "./therapists.JSON";
import Therapist1 from "../assets/DrEknath.jpg";
import Therapist2 from "../assets/DrGauri.jpg";
import Therapist3 from "../assets/drAvatar.jpeg";
import { Collapse } from "react-collapse";
import openArrow from "../assets/open-arrow.png";
import closeArrow from "../assets/close-arrow.png";
import plusIcon from "../assets/plusIcon.svg";
import LogoNew from "../assets/images/maulihospital.png";
import { Helmet } from "react-helmet";

const Schedule = () => {
  const [consultancyFlag, setcollapseOpenCloseConsultancy] = useState(false);
  const [emergencyFlag, setcollapseOpenCloseEmergency] = useState(false);
  const [OTFlag, setcollapseOpenCloseOT] = useState(false);
  const [ambulanceFlag, setcollapseOpenCloseAmbulance] = useState(false);

  let scheduleData = [
    {
      scheduleName: "Consultancy",
      functionName: setcollapseOpenCloseConsultancy,
      scheduleFlag: consultancyFlag,
      className: "Consultancy",
      Availability: "Monday to Saturday <br/> 11.00 AM to 2.00 PM <br/> 6.00 PM to 9.00 PM",
    },
    {
      scheduleName: "Operation Theatre",
      functionName: setcollapseOpenCloseOT,
      scheduleFlag: OTFlag,
      className: "OT",
      Availability: "Available for 24/7",
    },
    {
      scheduleName: "Emergency ICU",
      functionName: setcollapseOpenCloseEmergency,
      scheduleFlag: emergencyFlag,
      className: "Emergency",
      Availability: "Available for 24/7",
    },
    {
      scheduleName: "24 hrs Ambulance",
      functionName: setcollapseOpenCloseAmbulance,
      scheduleFlag: ambulanceFlag,
      className: "Ambulance",
      Availability: "Available for 24/7",
    },
  ];

  return (
    <>
  <Helmet>
     
    <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
    <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
    </Helmet>
    <div className="w-80 mx-auto mb-100 mt-20 ">
     <div className="flex flex-row xs:flex-col sm:flex-col md:flex-col  justify-between w-full mx-auto mb-50 ">{/* px-100 */}
							<div className="float-left mr-40 mt-10 lg:w-70% xl:w-40% 2xl:w-40%">
								<div className="heading primary-heading inner-heading">
									<div className="title-holder flex flex-row w-100%">
										<div className="title-block mr-0 xs:w-100% sm:w-100% md:w-100% w-100% lg:mr-20  xl:mr-20 2xl:mr-20">
											<h3 className="title xs:text-12 text-16 text-hyperlink  leadin-27 font-normal mb-10">Schedule</h3>
											<h1 className="subtitle xs:text-16 sm:text-16 md:text-20 text-40 text-black leading-27 font-bold">Our Availability</h1>
										</div>
										<div className="inline-block xs:w-50% sm:w-50% md:w-50% w-100% relative ">
											<img className='xs:h-80 sm:h-100% md:h-100% h-50% absolute bottom-0' src={LogoNew} alt="Mauli Hospital Logo HD"/>
										</div>
									</div>
								</div>
							</div>
							<div className="float-right mt-50 w-full">
								<div className="heading-description ">
									<p className="text-text-desc xs:text-sm sm:text-sm md:text-sm text-md text-justify"> We always available for health care at Mauli Hospital And Research Center, Satara. 
                  We provide Ambulance services, <b className=" text-red text-15">ICU services 24/7</b> in our hospital
                  </p>
                 {/*  <a class="button button--transparent button--common w-40% mt-30" onClick={()=>history.replace("/services")} data-hover="View Our Hospital Services"><span className="text">View Our Hospital Services</span></a> */}
								</div>
							</div>
      </div>
      <div className="grid grid-cols-1 mb-5">
        {scheduleData &&
          scheduleData.map((data, index) => (
            <div className={`${data.className} mr-10 my-10`}>
              <div className=" text-center p-5 doctors-accordian overflow-hidden" onClick={() => data.functionName(!data.scheduleFlag)}>
                <div className="overflow-hidden flex flex-row hover:cursor-pointer">
                  <div
                    className="mr-5 w-full font-bold text-left p-5"
                  >
                    {data.scheduleName}
                  </div>
                  <img className='w-18 h-18 my-auto' src={data.scheduleFlag ? openArrow:closeArrow}/>
                </div>
                <Collapse isOpened={data.scheduleFlag}>
                  <div className=" text-center p-5 m-3 ">
                    <div className=" text-center p-5 m-3 ">
                      <p
                        className="py-1 text-16 font-serif"
                        dangerouslySetInnerHTML={{ __html: data.Availability }}
                      ></p>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          ))}
      </div>
    </div>
    </>
  );
};

export default Schedule;
