import React, { Component } from "react";
import Popup from "reactjs-popup";
import { useActions } from "../Actions";
import { store } from "../Store";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Close from "../assets/close.png";

const WorkInProgressPopUp = (props) => {
  const { state, dispatch } = useContext(store);
  const actions = useActions(state, dispatch);

  const contentStyleForDesktopView = {
    padding: "0px",
    alignSelf: "center",
    width: "1000px",
    height: "600px",
    borderRadius: "16px",
    'overflow-y':'auto'
  };

  const { togglePopup, service } = props;

  const handleCloseClick = () => {
    actions.toggleServicePopUp(false);
  };

  return (
    <Popup
      contentStyle={contentStyleForDesktopView}
      open={togglePopup}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      repositionOnResize={false}
      lockScroll={true}
      modal
    >
      <div className="relative">
        <div className="cursor-pointer cross mt-15 mr-15 m-10 w-20 h-20 float-right" id="cross-img">
          <img src={Close} onClick={()=>handleCloseClick()}/>
        </div>
        <div className="p-10 scroll-auto">
          <h1 className="flex justify-center py-4 mx-auto text-20 font-bold">
            {service && service.name}
          </h1>
          <p className="text-16 text-black text-left pb-4 p-10 leading-7 font-normal" dangerouslySetInnerHTML={{ __html:service.description }}>
           {/*  {service.description} */}
          </p>

          <div className="flex justify-center py-4 ">
            <button
              className="button black-btn btn-sm w-30% p-5 bottom-0"
              onClick={() => handleCloseClick()}
            >
              <span className='text'> Close</span>
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default WorkInProgressPopUp;
