import React, { useEffect, useState } from "react";
import Therapist from "./therapist";
import Doctor from "./doctor";
import TherapistJson from "./therapists.JSON";
import Therapist1 from "../assets/DrEknath.jpg";
import Therapist2 from "../assets/DrGauri.jpg";
import { Collapse } from "react-collapse";
import openArrow from "../assets/open-arrow.png";
import closeArrow from "../assets/close-arrow.png";
import plusIcon from "../assets/plusIcon.svg";
import LogoNew from "../assets/images/maulihospital.png";
import { Helmet } from "react-helmet";

const Therapists = () => {
  const [GnSurgeonFlag, setcollapseOpenCloseGnSurgeon] = useState(false);
  const [OncoFlag, setCollapseOpenCloseOnco] = useState(false);
  const [EuroFlag, setCollapseOpenCloseEuro] = useState(false);
  const [NeuroFlag, setCollapseOpenCloseNeuro] = useState(false);
  const [OrthoFlag, setCollapseOpenCloseOrtho] = useState(false);
  const [AnesthetistFlag, setCollapseOpenCloseAnesthetist] = useState(false);
  const [GynaecoLogist, setCollapseOpenCloseGynaecoLogist] = useState(false);
  const [ENTSpeialistFlag, setCollapseOpenCloseENTSpeialist] = useState(false);
  const [PediatricianFlag, setCollapseOpenClosePediatrician] = useState(false);
  const [AyurvedaFlag, setCollapseOpenCloseAyurveda] = useState(false);
  const [MedicineFlag, setCollapseOpenCloseMedicine] = useState(false);
  let doctorsData = [
    {
      classificationOfSurgeon: "Consultant/General Surgeons",
      functionName: setcollapseOpenCloseGnSurgeon,
      functionFlag: GnSurgeonFlag,
      className: "general-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Eknath Jadhav (M.S.)",
          designation:'(Consultant Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:false
        },
        {
          image: Therapist2,
          name: "Dr. Gaurie Jadhav (M.S.)",
          designation:'(Consultant Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:false
        },
        {
          image: Therapist2,
          name: "Dr. Sanjay Patil",
          designation:'(General Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Sandip Shotri",
          designation:'(General Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "Onco Surgeons",
      functionName: setCollapseOpenCloseOnco,
      functionFlag: OncoFlag,
      className: "onco-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Rakesh Neve",
          designation:'(Onco Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "Uro Surgeons",
      functionName: setCollapseOpenCloseEuro,
      functionFlag: EuroFlag,
      className: "euro-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Vikramm Paramane",
          designation:'(Uro Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Sayali Patil",
          designation:'(Uro Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Tanmay Shete",
          designation:'(Uro Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "Neuro Surgeons",
      functionName: setCollapseOpenCloseNeuro,
      functionFlag: NeuroFlag,
      className: "Neuro-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Manish Tapase",
          designation:'(Neuro Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Chaitanya Godbole",
          designation:'(Neuro Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "Ortho Surgeons",
      functionName: setCollapseOpenCloseOrtho,
      functionFlag: OrthoFlag,
      className: "Ortho-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Umesh Patil",
          designation:'(Ortho Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Ajay Pawar",
          designation:'(Ortho Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Rahul Patil",
          designation:'(Ortho Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "Anasthesiologist",
      functionName: setCollapseOpenCloseAnesthetist,
      functionFlag: AnesthetistFlag,
      className: "Anasthesiologist",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. V. H. Kulkarni",
          designation:'(Anasthesiologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
       {
          image: Therapist2,
          name: "Dr. Parimal Yawatkar",
          designation:'(Anasthesiologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        }, 
        {
          image: Therapist2,
          name: "Dr. Chaitali Jathar-Thorat",
          designation:'(Anasthesiologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        }, 
        {
          image: Therapist2,
          name: "Dr. Prashant Mahamuni",
          designation:'(Anasthesiologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        }, 
      ],
    },
    {
      classificationOfSurgeon: "GynaecoLogist",
      functionName: setCollapseOpenCloseGynaecoLogist,
      functionFlag: GynaecoLogist,
      className: "Gynaecologist",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Kiran Jadhav",
          designation:'(Gynaecologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Snehanjali Mahamuni",
          designation:'(Gynaecologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Prasad Kadam",
          designation:'(Gynaecologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Sunita Lipare",
          designation:'(Gynaecologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Kiran Sonwalkar",
          designation:'(Gynaecologist)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "ENT Surgeons",
      functionName: setCollapseOpenCloseENTSpeialist,
      functionFlag: ENTSpeialistFlag,
      className: "ENT-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Aamod Pendharkar",
          designation:'(ENT Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Sanket Prabhune",
          designation:'(ENT Surgeon)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
      ],
    },
    {
      classificationOfSurgeon: "Pediatrician",
      functionName: setCollapseOpenClosePediatrician,
      functionFlag: PediatricianFlag,
      className: "Pediatrician-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Amol Pawar",
          designation:'(Pediatrician)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Rajendra Patil",
          designation:'(Pediatrician)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        }
      ],
    },
    {
      classificationOfSurgeon: "Ayurvedachary",
      functionName: setCollapseOpenCloseAyurveda,
      functionFlag: AyurvedaFlag,
      className: "Ayurveda-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Sadyen Deshpande",
          designation:'(Ayurvedachary)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Samir Shinde",
          designation:'(M.D. Ayu.)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Prasad Kadam",
          designation:'(M.D. Ayu.)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        }
      ],
    },
    {
      classificationOfSurgeon: "Medicine",
      functionName: setCollapseOpenCloseMedicine,
      functionFlag: MedicineFlag,
      className: "Medicine-surgeons",
      therapistData: [
        {
          image: Therapist1,
          name: "Dr. Dattatrai More",
          designation:'(D.N.B Med.)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        },
        {
          image: Therapist2,
          name: "Dr. Debosmita Sangali",
          designation:'(M.D. Med.)',
          AvailableDay: "All Day",
          AvailableTime: "11.00 AM to 6.00 PM",
          AppointmentFlag:true
        }
      ],
    },
  ];

  return (
    <>
     <Helmet>
        <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
        <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
      </Helmet>
    <div className="w-80 mx-auto mb-100 mt-20 ">
     <div className="flex flex-row xs:flex-col sm:flex-col md:flex-col justify-between w-full mx-auto mb-50 ">
							<div className="float-left mr-40 mt-10 lg:w-70% xl:w-40% 2xl:w-40%">
								<div className="heading primary-heading inner-heading">
									<div className="title-holder flex flex-row w-100%">
										<div className="title-block mr-0 xs:w-100% sm:w-100% md:w-100% w-100% lg:mr-20  xl:mr-20 2xl:mr-20">
											<h3 className="title xs:text-12 text-16 text-hyperlink  leadin-27 font-normal mb-10">Panel of Doctors</h3>
											<h1 className="subtitle xs:text-16 sm:text-16 md:text-20 text-40 text-black leading-27 font-bold">Our Best Doctors</h1>
										</div>
										<div className="inline-block xs:w-50% sm:w-50% md:w-50% w-100% relative ">
											<img className='xs:h-80 sm:h-100% md:h-100% h-50% absolute bottom-0' src={LogoNew} alt="Mauli Hospital Logo HD"/>
										</div>
									</div>
								</div>
							</div>
							<div className="float-right mt-50 w-full">
								<div className="heading-description ">
									<p className="text-text-desc xs:text-sm sm:text-sm md:text-sm lg:text-sm text-md text-justify"> At Mauli Hospital And Research Center, Satara, we support you every step of the way through your care. 
                  We’ve gathered a team of leading specialists who are committed to providing you with the most intuitive and comfortable treatments. 
                  The following list will help you to find the doctor that is right for you. Becoming a Doctor is not only a matter of prestige and draws a lot of respect, 
                  but along with it comes the big responsibility of saving people’s lives as well as ensuring they live healthy ones. 
                  Keeping in mind the various parts and their complexities, there are different types of doctors who specialize in different human body anatomy 
                  and help diagnose the cause of illness or discomfort. 
                  </p>
								</div>
							</div>
      </div>

      <div className="grid grid-cols-1 mb-5">
        {doctorsData &&
          doctorsData.map((data, index) => (
            <div className={`${data.className}  mr-10 my-10`}>
              <div className=" text-left doctors-accordian overflow-hidden" onClick={() => data.functionName(!data.functionFlag)}>
                <div className="overflow-hidden flex flex-row hover:cursor-pointer" >
                  <div className="mr-5 w-full font-bold text16 p-5">
                    {data.classificationOfSurgeon}
                  </div>
                  <img className='w-18 h-18 my-auto' src={data.functionFlag ? openArrow:closeArrow}/>
                </div>
                
                <Collapse isOpened={data.functionFlag}>
                <div className="mb-5">
                <div className=" text-center m-3 ">
                      <div className="content-block block" >
                        <div className="table-responsive block w-100% overflow-auto">
                            <table className="table w-100% text-black border mb-10 table-bordered table-striped xs:text-10 sm:text-10 md:text-10">
                                <thead className="bg-table-green text-white border">
                                    <tr className="">
                                        <th className="border py-10">Name</th>
                                        <th className="border">OPD Day</th>
                                        <th className="border">OPD Timings</th>
                                    </tr>                                
                                </thead>
                          {data.therapistData.map((therapist) => (
                            <Doctor
                              key={therapist.name}
                              therapist={therapist}
                            ></Doctor>
                          ))}
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
              </Collapse>
              </div>
            </div>
          ))}
      </div>
    </div>
    </>
  );
};
export default Therapists;
