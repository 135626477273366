import React from "react";

const Images = (props) => {
  const { name, image } = props && props.images;
  return (
    <div className=" text-center m-3 w-100% border">{/* h-300 w-300  */}
      <div className="">
        <img
          className="gallary-image w-450 h-350"
          src={image}
          alt={name}
        />
      </div>
    </div>
  );
};

export default Images;
