import React from "react";
import picture from "../assets/folder.png";
import play from "../assets/play.png";
import Img1 from "../assets/images/services/1.png";
import Img2 from "../assets/images/services/2.png";
import Img3 from "../assets/images/services/3.png";
import Img4 from "../assets/images/services/4.jpg";
import Img5 from "../assets/images/services/5.png";
import Img6 from "../assets/images/services/6.jpg";
import Img7 from "../assets/images/services/7.png";
import Img8 from "../assets/images/services/8.png";
import Img9 from "../assets/images/services/9.png";
import Img10 from "../assets/images/services/10.png";
import Img11 from "../assets/images/services/11.png";
import Img12 from "../assets/images/services/12.png";
import Img13 from "../assets/images/services/13.jpg";
import Img14 from "../assets/images/services/14.jpg";
import Img15 from "../assets/images/services/15.jpg";
import Img16 from "../assets/images/services/16.jpg";
import Img17 from "../assets/images/services/17.jpg";
import Img18 from "../assets/images/services/18.jpg";
import Img19 from "../assets/images/services/19.jpg";
import Img20 from "../assets/images/services/20.jpg";
import Img21 from "../assets/images/services/21.jpg";
import Img22 from "../assets/images/services/22.jpg";
import Img23 from "../assets/images/services/23.jpg";
import Img24 from "../assets/images/services/24.jpg";
import Img25 from "../assets/images/services/25.jpg";
import Img26 from "../assets/images/services/26.jpg";
import Img27 from "../assets/images/services/27.jpg";
import Img28 from "../assets/images/services/28.jpg";

import AnImg1 from "../assets/images/annualevents/1.jpg";
import AnImg2 from "../assets/images/annualevents/2.jpg";
import AnImg3 from "../assets/images/annualevents/3.jpg";
import AnImg4 from "../assets/images/annualevents/an4.jpg";
import AnImg5 from "../assets/images/annualevents/5.jpg";

import NABHImg1 from "../assets/images/nabhevent/nabh1.jpg";
import NABHImg2 from "../assets/images/nabhevent/nabh2.jpg";

import SMImg1 from "../assets/images/socialmedia/sm1.jpg";
import SMImg2 from "../assets/images/socialmedia/sm2.jpg";
import SMImg3 from "../assets/images/socialmedia/sm3.jpg";
import SMImg4 from "../assets/images/socialmedia/sm4.jpg";

import ImagesGrid from "./images";
import VideosGrid from "./videos";
import MauliVideo from "../assets/videos/MauliVideo.mp4";
import Digestion from "../assets/videos/Digestion.mp4";
import MauliVideo2 from "../assets/videos/MauliVideo2.mp4";
import Verocose from "../assets/videos/Vericose.mp4"; 

import campa1 from "../assets/images/camp/arvi/campa1.jpg"; 
import campa2 from "../assets/images/camp/arvi/campa2.jpg"; 
import campa3 from "../assets/images/camp/arvi/campa3.jpg"; 
import campa4 from "../assets/images/camp/arvi/campa4.jpg"; 

import achievement1 from "../assets/images/achievements/achievement1.jpg"; 
import achievement2 from "../assets/images/achievements/achievement2.jpg";

import ot1 from "../assets/images/OT/OT1.jpg"; 
import ot2 from "../assets/images/OT/OT2.jpg"; 

import ERCP1 from "../assets/images/ERCP/ERCP1.jpg"; 
import ERCP2 from "../assets/images/ERCP/ERCP2.jpg"; 
import ERCP3 from "../assets/images/ERCP/ERCP3.jpg"; 
import ERCP4 from "../assets/images/ERCP/ERCP4.jpg";  
import ERCP5 from "../assets/images/ERCP/ERCP5.jpg";


import plusIcon from "../assets/plusIcon.svg";import LogoNew from "../assets/images/maulihospital.png";
import { Helmet } from "react-helmet";

import { useActions } from "../Actions";
import { store } from "../Store";
import { useContext, useEffect, useState } from "react";
const Gallary = () => {
  const { state, dispatch } = useContext(store);
  const actions = useActions(state, dispatch);
  const { showImages, showVideos } = state;
  const [imageFolder, setSelectedImageFolder] = useState(false);

  const handleImageClick = (keyss) => {
    actions.showImages(true);
    setSelectedImageFolder(keyss)
  };
  let images = [
    { image: Img1 },
    { image: Img2 },
    { image: Img3 },
    { image: Img4 },
    { image: Img5 },
    { image: Img6 },
    { image: Img7 },
    { image: Img8 },
    { image: Img9 },
    { image: Img10 },
    { image: Img11 },
    { image: Img12 },
    { image: Img13 },
    { image: Img13 } , { image: Img14 },  { image: Img15 },  { image: Img16 },  { image: Img17 },  { image: Img18 },  { image: Img19 },  { image: Img20 },  
    { image: Img21 },  { image: Img22 },  { image: Img23 },  { image: Img24 },  { image: Img25 },  { image: Img26 },  { image: Img27 },{ image: Img28 }
  ];
  let AnualEvents = [
    { image: AnImg1 },
    { image: AnImg2 },
    { image: AnImg3 },
    { image: AnImg4 },
    { image: AnImg5 },
  ];
  let NABH = [
    { image: NABHImg1 },
    { image: NABHImg2 },
  ];
  let SocialMedia=[
    { image: SMImg1 },
    { image: SMImg2 },
    { image: SMImg3 },
    { image: SMImg4 },
  ];
  let videos = [
    {video: Digestion,name: "Digestion",},
    {video: Verocose,name: "Vericose Treatment at Mauli Hospital",},
    {video: MauliVideo,name: "Moduler OT,Mauli Hospital,Satara",},
    {video: MauliVideo2,name: "Mauli Hospital,Satara",},
  ];
  let camp_arvi = [
    {image: campa1,},
    {image: campa2,},
    {image: campa3,},
    {image: campa4,},
  ];
  let achievements = [
    {image: achievement1,},
    {image: achievement2,}
  ];
  let OT = [
    {image: ot1,},
    {image: ot2,}
  ];
  let ERCP = [
    {image: ERCP1,},
    {image: ERCP2,},
    {image: ERCP3,},
    {image: ERCP4,},
    {image: ERCP5,},
  ];
  let imgLoop=['OT','ERCP','Services','Annual Events','NABH Event','Social Media','Achievements','Camp - Arvi','Videos']
  const keys = Object.values(imgLoop);

  let filteredFolder = 
  imageFolder === 'OT'?OT:
  imageFolder === 'ERCP'?ERCP:
  imageFolder === 'Services'?images:
  imageFolder === 'Annual Events'?AnualEvents:
  imageFolder === 'NABH Event'?NABH:
  imageFolder === 'Social Media'?SocialMedia:
  imageFolder === 'Videos'?videos:
  imageFolder === 'Camp - Arvi'?camp_arvi:
  imageFolder === 'Achievements'?achievements:''

  return (
    <>
   <Helmet>
    <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
    <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
    </Helmet>
    <div className="w-80 mx-auto flex md:flex-col flex-col mb-100 mt-20">
     <h3 className="title text-40 text-center text-black leadin-27 font-bold mb-10 p-10">Gallary</h3>
    
     <div className="flex xs:flex-col sm:flex-col md:flex-col flex-row justify-center my-10">
     {keys.map((keyss) => (
        <div
          className={`text-center p-5 m-3  overflow-hidden xl:mr-56 xs:flex sm:flex md:flex xs:flex-row sm:flex-row md:flex-row`}
          onClick={()=>handleImageClick(keyss)}
        >
          <div className="overflow-hidden hover:cursor-pointer">
            <img
              className="image overflow-hidden  w-400 h-200"
              src={keyss=== 'Videos'?play:picture}
              alt={keyss=== 'Videos'?play:picture}
            />
          </div>
          <p className={`${imageFolder ===keyss ? 'font-bold' :''} py-1 text-16 xs:m-40 sm:m-40 md:m-40 `} dangerouslySetInnerHTML={{ __html: keyss }}></p>
        </div>
       ))}

      </div>
      {showImages && (
        <div className="grid mx-auto xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 grid-cols-4 my-5 gap-10">
          {imageFolder != 'Videos' && filteredFolder && filteredFolder.map((image) => (
            <ImagesGrid key={image} images={image}></ImagesGrid>
          ))}
            {imageFolder === 'Videos' && filteredFolder && filteredFolder.map((video) => (
            <VideosGrid key={video.name} videos={video}></VideosGrid>
          ))}
        </div>
      )}
    </div>
    </>
  );
};
export default Gallary;
