import "babel-polyfill"
import ReactDom, { render } from "react-dom";
import React from "react"
import App from './app';
import { StateProvider } from './Store'
import {HelmetProvider} from 'react-helmet-async'
ReactDom.render(
    <StateProvider>
        <HelmetProvider><App /></HelmetProvider>
        </StateProvider>,document.getElementById("root")
)