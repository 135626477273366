export default {
  SET_PROJECT_NAME: "SET_PROJECT_NAME",
  API_ERROR: "API_ERROR",
  UNSET_LOADING_INDICATOR: "UNSET_LOADING_INDICATOR",
  GET_FIRST_API_CALL_REQUEST: "GET_FIRST_API_CALL_REQUEST",
  GET_FIRST_API_CALL_REQUEST_SUCCESS: "GET_FIRST_API_CALL_REQUEST_SUCCESS",
  TOGGLE_SERVICE_POPUP: "TOGGLE_SERVICE_POPUP",
  SAVE_SELECTED_SERVICE: "SAVE_SELECTED_SERVICE",
  SHOW_GALLARY_IMAGES: "SHOW_GALLARY_IMAGES",
  SHOW_GALLARY_VIDEOS: "SHOW_GALLARY_VIDEOS",
  SET_SELECTED_TAB_NAME: "SET_SELECTED_TAB_NAME",
  SET_CUSTOMER_NAME:'SET_CUSTOMER_NAME',
  SET_CUSTOMER_MOBILE_NUMBER:'SET_CUSTOMER_MOBILE_NUMBER',
  SET_CUSTOMER_EMAL:'SET_CUSTOMER_EMAL',
  SET_SCUSTOMER_MSG:'SET_SCUSTOMER_MSG'
};
