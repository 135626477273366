import React from "react";

const Therapist = (props) => {
  const { name, image, designation,AvailableDay, AvailableTime } = props && props.therapist;
  return (
    <div className=" text-center m-3  therapist hover:text-greyCyan xs:w-250 sm:w-300 md:w-300 lg:w-300 xl:w-300 2xl:w-300">
      <div className="overflow-hidden">
        <img
          className="therapist-image  overflow-hidden"
          src={image}
          alt={name}
        />
      </div>
        <div id='consultants'class=" bg-table-green p-10 xs:w-250 sm:w-300 md:w-300 lg:w-300 xl:w-300 2xl:w-300 scale-50">
          <span class="name xs:text-14 text-16 font-bold text-white"  dangerouslySetInnerHTML={{ __html: name }}></span><br/>
        <span class="position xs:text-12 text-14 opacity-50 text-white" dangerouslySetInnerHTML={{ __html: designation }} ></span></div>
    </div>
  );
};

export default Therapist;
