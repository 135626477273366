import React from "react";
import Call from "../assets/socialmedia/ic-call.png";
import Email from "../assets/socialmedia/ic-email.png";
import Facebook from "../assets/socialmedia/ic-fb.png";
import Google from "../assets/socialmedia/ic-google.png";
import Twitter from "../assets/socialmedia/ic-twitter.png";
import Instagram from "../assets/socialmedia/ic-instagram.png";

const Footer = () => {
  return (
    <div className="footer fixed bottom-0  w-screen">
      <div className="flex flex-row bg-bg-gray text-white justify-between">
        <div className="footer-parts flex flex-col ml-10 mt-10 float-left">
          <ul>
            <li>
              <a href="#" className="text-12 flex flex-row mb-4 hover:cursor-pointer">
                <img src={Call} className=" w-18 h-18"></img>
                &nbsp; +91-8308289090 &nbsp; / &nbsp;02162-244423
              </a>
            </li>
            <li>
              <a
                href="mailto:maulihospital.ins@gmail.com"
                className="text-12 flex flex-row mb-4 hover:cursor-pointer"
              >
                <img src={Email} className=" w-18 h-18"></img>
                &nbsp; maulihospital.ins@gmail.com
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-parts flex flex-col  mt-10 mr-20 float-right">
          <div className="flex flex-row mt-10">
            <a
              href="https://www.facebook.com/freetimelearn/"
              className="text-12 hover:cursor-pointer"
              target="_blank"
            >
              <img src={Facebook} className=" w-24 h-24"></img>
            </a>{" "}
            &nbsp; &nbsp;
            <a
              href="https://www.justdial.com/Satara/Mauli-Hospital-And-Research-Centre-Near-Morya-Mall-Satara-Midc/"
              className="text-12 hover:cursor-pointer"
              target="_blank"
            >
              <img src={Google} className=" w-24 h-24"></img>
            </a>{" "}
            &nbsp; &nbsp;
            <a
              href="https://instagram.com/mauli_hospital16"
              target="_blank"
              className="text-12 hover:cursor-pointer"
            >
              <img src={Instagram} className=" w-24 h-24"></img>
            </a>{" "}
            &nbsp; &nbsp;
            <a
              href="https://www.justdial.com/Satara/Mauli-Hospital-And-Research-Centre-Near-Morya-Mall-Satara-Midc"
              className="text-12 hover:cursor-pointer"
              target="_blank"
            >
              <img src={Twitter} className=" w-24 h-24"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="copyrights bg-bg-black text-white h-40 text-10">
        <div className="float-left mt-10 ml-10">
          &copy; 2023. All rights reserved by{" "}
          <a href="" target="_blank">
            MHARC Satara.
          </a>
        </div>
        <div className="float-right mt-10 mr-30">
          Designed by{" "}
          <a href="" target="_blank" title='Mauli Tweeter'>
           Ti22Tech
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
