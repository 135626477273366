import React from "react";
import { Carousel } from "./carousel";
import { CarouselLogo } from "./carousel2";

import carousel1 from "../assets/drAvatar.jpeg";
import carousel2 from "../assets/drAvatar.jpeg";
import drEknath from "../assets/DrEknath 2.jpg";
import drGauri from "../assets/G.jpg";
import team from "../assets/team.jpg";
import team2 from "../assets/team2.jpg";
import banner from "../assets/banner.jpg";
import healing from "../assets/healing.jpg";
import healing_new from "../assets/healing_new.jpg";
import banner1 from "../assets/banner2.jpg";
import knee from "../assets/knee5.png";
import plusIcon from "../assets/plusIcon.svg";import LogoNew from "../assets/images/maulihospital.png";
import hallmark1 from "../assets/socialmedia/hallmark1.jpg";
import hallmark2 from "../assets/socialmedia/iso3.jpg";
import hallmark3 from "../assets/socialmedia/hallmark3.jpg";
import hallmark4 from "../assets/socialmedia/hallmark4.jpg";
import {Helmet} from 'react-helmet'

import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  let images = [
    {
      src: drEknath,
      alt: "slide-2",
      content: `"I will <span class="text-red">Win</span> not immediately but definitely. <br/>
      If you can dance and be free and be embarrassed, you can rule the world."<br/><br/>`,
      footerText: `<p className="text-16 font-bold text-teal-400">Dr. Eknath Jadhav (M.S.) <br/> Chairman <br/>Consulting Surgeon,</p>
      Samarth Nagar, Plot No.17, Shivraj colony, Degav Phata, <br/>
      MIDC, Satara, Maharashtra 415004`,
    },
    {
      src: drGauri ,
      alt: "slide-1",
      content: `"I believe in being <span class='text-red'>Strong</span> when everything seems to be going wrong.<br/>
      I believe that happy girls are the prettiest girls. <br/>I believe that tomorrow is another day,
      and I believe in miracles."`,
      footerText: `<span className="text-16 font-bold text-teal-400">Dr. Gauri Jadhav (M.S.) <br/> Director </br>Consulting Surgeon,</span><br/>
      Samarth Nagar, Plot No.17, Shivraj colony, Degav phata, <br/>
      MIDC, Satara, Maharashtra 415004`,
    },
    {
      src: team,
      alt: "slide-2",
      onlyImage:true,
      content: ``,
      footerText: ``,
    },
    {
      src: team2,
      alt: "slide-2",
      onlyImage:true,
      content: ``,
      footerText: ``,
    },
  ];
  let hallmark = [
    {
      src: hallmark1,
      
      alt: "slide-1",
    },
    {
      src: hallmark2,
      alt: "slide-2",
    },
    {
      src: hallmark4,
      alt: "slide-2",
    },
  ];
  return (
    <>
   <Helmet>
        <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
        <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
      </Helmet>
    <div>
      <div className="flex flex-col"></div>
        <div className="relative w-full block bg-white pb-8"> 
          <img className="block  text-center m-auto" src={healing_new}/>
          <div className="text-center leading-27 font-ApercuRg text-40 mt-40">A Place Where Healing Starts...</div>
          </div>
     <div className="w-full flex flex-col">
      <div className="block text-block text-center 2xl:text-24 xl:text-24 text-16">
					<p className="filled-text lg:leading-8 xl:leading-8 2xl:leading-8 2xl:p-20 xl:p-20 lg:p-20 p-10">
            <marquee className='font-ApercuRg flex' id="scroll_news" onmouseout="document.getElementById('scroll_news').start();" onmouseover="document.getElementById('scroll_news').stop();">
                    Stay FIT - Stay HEALTHY - Stay STRONG! | Mauli Hospital Wishes you Happy &amp; Healthy Life
            </marquee>
            </p>
			</div>
    </div>
    <div className="flex flex-row xs:flex-col sm:flex-col md:flex-col justify-between w-full mx-auto md:px-0  xs:px-0 sm:px-0 lg:px-100 xl:px-100  px-300 xs:mt-16 sm:mt-16 md:mt-16 mt-50">
							<div className="xs:float-none sm:float-none md:float-none xs:text-center sm:text-center md:text-center float-left xs:mr-0 sm:mr-0 md:mr-0 mr-60 mt-10">
									<div className=" flex xs:flex-col sm:flex-col md:flex-col flex-row title-holder">
										<div className="title-block mr-10 lg:w-300% xl:w-300%">
											<h3 className="title text-16 text-hyperlink  leadin-27 font-normal mb-10">Introduction</h3>
											<h1 className="subtitle xs:text-20 sm:text-20 md:text-20 lg:text-20 text-40 text-black leading-27 font-bold">Welcome to Mauli<br/> Hospital And Research Center, Satara</h1>
										</div>
                    <div className="lg:hidden xl:hidden 2xl:hidden block img-block w-full  ">
											<img className='h-10 w-10% m-auto  bottom-0' src={LogoNew} alt="Mauli Hospital Logo HD"/>
										</div>
										<div className="xs:hidden sm:hidden md:hidden  img-block w-full relative ">
											<img className='h-auto absolute bottom-0' src={LogoNew} alt="Mauli Hospital Logo HD"/>
										</div>
									</div>
							</div>
							<div className="xs:text-center sm:text-center md:text-center xs:float-none sm:float-none md:float-none float-right mt-50 xs:mt-16 sm:mt-16 md:mt-16 text-justify">
								<div className="heading-description xs:p-10 sm:p-10 md:p-10 xs:text-center sm:text-center md:text-center ">
									<p className="text-text-desc xs:text-sm sm:text-sm md:text-sm text-md"> Mauli Hospital And Research Center, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the <b>top hospitals in Satara(Maharashtra)</b>.</p>
                  <a className="button lg:px-10 xl:px-10 2xl:px-10 lg:py-10 xl:py-10 2xl:py-10 button--transparent button--common xl:w-60 2xl:w-50% lg:w-50%  xs:mx-60 sm:mx-80 md:mx-80 mt-30" onClick={()=>history.replace("/services")} data-hover="View Our Hospital Services">
                    <span className="text xs:text-sm sm:text-sm md:text-sm text-16">View Our Hospital Services</span></a>
								</div>
							</div>
    </div>
    <div className="flex flex-col justify-center w-full mx-auto md:px-0 xs:px-0 sm:px-0 lg:px-100 xl:px-100  px-300 xs:mt-16 sm:mt-16 md:mt-16 mt-50  text-text-desc text-md">
    <div className="xs:mx-10% sm:mx-10% md:mx-10% mx-20% bg-bg-blue text-white p-30 xs:text-sm sm:text-sm md:text-sm text-16 text-justify">Hallmark of our quality assurance,
  commitment to quality is an integral part of our care with the aim of clinical excellence and patient satisfaction and safety. We achieve this by continually 
  improving our capability to identify, develop and provide services that are valued by our patients and result in positive clinical outcomes.
<ul className="text-md xs:p-0 sm:p-0 md:p-0 xs:pt-30 sm:pt-30 md:pt-30 p-30 text-white xs:text-sm sm:text-sm md:text-sm text-16">
<li style={{'list-style':'disc'}}>NABH for Mauli Hospital And Research Center, Satara</li>
<li style={{'list-style':'disc'}}>NABH for Nursing Excellence</li>
<li style={{'list-style':'disc'}}>ISO certified center, Satara</li>
<li style={{'list-style':'disc'}}>First Hospital in Satara to receive the Green OT Certificate</li>
</ul>
</div>
      <div className="xs:block sm:block md:block hidden p-60">
        <CarouselLogo images={hallmark} />
      </div>
      <div className="xs:hidden sm:hidden md:hidden block xs:p-30 sm:p-30 md:p-30 p-60 ">
      <div className="mt-20 ">
    <ol className="relative list-none flex flex-row justify-center">
      {hallmark.map((img, index) => (
         <div key={index}>
         <div className="flex justify-center">
           <div className="">
             <img
               key={img.alt}
               className="border border-gray-600 rounded-50% max-w-90% float-right"
               src={img.src}
               alt={img.alt}
               intrinsicsize="350x350"
             />
           </div>
         </div>
       </div>
      ))}
    </ol>
  </div>
      </div>
    </div>
    <div className="flex flex-row justify-center w-full mx-auto md:px-0 xs:px-0 sm:px-0 lg:px-100 xl:px-100  px-300 xs:mt-16 sm:mt-16 md:mt-16 mt-50 text-text-desc text-mdner">
                <div className="w-50% mr-50 xs:mx-10 sm:mx-10 md:mx-10 lg:mx-10 xl:mx-10">
                    <figure className="book-item">
                        <figcaption>
                            <h4 className="title xs:text-16 sm:text-16 md:text-16 text-20 mb-10">Vision</h4>
                            <div className="text-holder">
                                <p className="text-black xs:text-sm sm:text-sm md:text-sm text-md">To deliver highest quality at affordable cost.</p>
                            </div>
                        </figcaption>
                    </figure>
                </div>
                <div className="w-50% xs:mx-10 sm:mx-10 md:mx-10 lg:mx-10 xl:mx-10">
                    <figure className="book-item">
                        <figcaption>
                            <h4 className="title xs:text-16 sm:text-16 md:text-16 text-20 mb-10">Mission</h4>
                            <div className="text-holder">
                                <p className="text-black xs:text-sm sm:text-sm md:text-sm text-md text-justify">Mauli Hospital And Research Center to evolve as a benchmark in quality healthcare.</p>
                            </div>
                        </figcaption>
                    </figure>
                </div>
        </div>
        <div className="flex flex-row justify-center w-full mx-auto md:px-0 xs:px-0 sm:px-0 lg:px-100 xl:px-100  px-300 xs:mt-16 sm:mt-16 md:mt-16 mt-50 mb-50 text-text-desc text-mdner">
                <div className="w-50% mr-50 xs:mx-10 sm:mx-10 md:mx-10 lg:mx-10 xl:mx-10">
                    <figure className="book-item">
                        <figcaption>
                            <h4 className="title xs:text-16 sm:text-16 md:text-16 text-20 mb-10">Policy</h4>
                            <div className="text-holder">
                                <p className="text-black xs:text-sm sm:text-sm md:text-sm text-md text-justify">The focus of all our activities are based on "patients first" policy. 
                                Our responsibility is to provide the best possible care and best outcome for every patient</p>
                            </div>
                        </figcaption>
                    </figure>
                </div>
                <div className="w-50% xs:mx-10 sm:mx-10 md:mx-10 lg:mx-10 xl:mx-10">
                    <figure className="book-item">
                        <figcaption>
                            <h4 className="title xs:text-16 sm:text-16 md:text-16 text-20 mb-10">Values</h4>
                            <div className="text-holder">
                            <p className="text-black xs:text-sm sm:text-sm md:text-sm text-md">
                            <ul className="ml-10">
                            <li className="list-disc">Quality Healthcare </li>
                            <li className="list-disc">Satisfaction</li>
                            <li className="list-disc">Respect</li>
                            <li className="list-disc">Integrity</li>
                            <li className="list-disc">Team Work</li></ul>
                            </p>
                            </div>
                        </figcaption>
                    </figure>
                </div>
        </div>
      <div className="relative w-full block bg-knee-bg">
          <img className="block  text-center m-auto lg:h-950 md:xl-950 2xl:h-950 h-950"src={knee}/>
          </div>
      <div className="flex flex-col justify-center w-full mx-auto md:px-0  xs:px-0 sm:px-0 lg:px-100 xl:px-100  px-300 xs:mt-16 sm:mt-16 md:mt-16 mt-50">
        <div className="heading primary-heading text-center">
                    <h3 className="title text-16 text-hyperlink  leadin-27 font-normal mb-20 ">Mauli Hospital And Research Center, <br/>Satara</h3>
                    <h5 className="subtitle xs:text-20 sm:text-20 md:text-20 text-40 leading-27 font-bold text-black"><span>Meet Our Mauli Hospitals Family</span></h5>
                  </div>
        <div className="border border-solid m-20 xs:p-10 sm:p-10 md:p-10 p-60">
          <Carousel images={images} />
        </div>
      </div>
    </div>
    </>
  );
};
export default Home;
