import React, { useEffect, useState } from "react";
import Therapist from "./therapist";
import TherapistJson from "./therapists.JSON";
import Therapist1 from "../assets/DrEknath.jpg";
import Therapist2 from "../assets/DrGauri.jpg";
import Therapist3 from "../assets/drAvatar.jpeg";
import plusIcon from "../assets/plusIcon.svg";
import LogoNew from "../assets/images/maulihospital.png";
import { Helmet } from "react-helmet";

const Consultants = () => {
  const [therapists, setTherapists] = useState([]);

  let therapistData = [
    {
      image: Therapist1,
      name: "Dr. Eknath Jadhav (M.S.) <br/>Chairman<br/>(Consulting Surgeon)",
      AvailableDay: "All Day",
      AvailableTime: "11 AM to 6 PM",
      designation:"Primary Consultant"
    },
    {
      image: Therapist2,
      name: "Dr. Gauri Jadhav (M.S.) <br/>Director<br/>(Consulting Surgeon)",
      AvailableDay: "All Day",
      AvailableTime: "11 AM to 6 PM",
      designation:"Primary Consultant"
    },
  ];

  return (
  <>
   <Helmet>
         
        <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
        <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
      </Helmet>
    <div className="w-80 mx-auto xs:flex-col sm:flex-col md:flex-col lg:flex-col flex flex-col mb-100 mt-20">
     <div className="flex flex-row xs:flex-col sm:flex-col md:flex-col justify-between w-full mx-auto mb-50 ">
							<div className="float-left mr-40 mt-10 lg:w-70% xl:w-40% 2xl:w-40%">
								<div className="heading primary-heading inner-heading">
									<div className="title-holder flex flex-row w-100%">
										<div className="title-block mr-0 xs:w-100% sm:w-100% md:w-100% w-100% lg:mr-20 xl:mr-20 2xl:mr-20">
											<h3 className="title xs:text-12 text-16 text-hyperlink  leadin-27 font-normal mb-10">Consultants</h3>
											<h1 className="subtitle xs:text-16 sm:text-16 md:text-20 text-40 text-black leading-27 font-bold">Our Best Consultants</h1>
										</div>
										<div className="inline-block xs:w-50% sm:w-50% md:w-50% w-100% relative">
											<img className='xs:h-80 sm:h-100% md:h-100% h-50% absolute bottom-0' src={LogoNew} alt="Mauli Hospital Logo HD"/>
										</div>
									</div>
								</div>
							</div>
							<div className="float-right mt-50 w-full">
								<div className="heading-description ">
									<p className="text-text-desc xs:text-sm sm:text-sm md:text-sm lg:text-sm text-md text-justify"> Welcome to our best consultants marks for standards in the health care industry and is marked as one of the <b>best doctors in Satara(Maharashtra)</b>.
                  Consultants are professionals who have rich experience in their field and a deep understanding of the industry they are in. Based on their expertise, 
                  they provide expert opinion, analysis and recommendations to a specific type of client, who may be an individual, an organisation or a group of people, 
                  to help them improve their performance.
                  </p>
								</div>
							</div>
      </div>
      <div className="grid mx-auto xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 mb-10 my-20 gap-10">
        {therapistData.map((therapist) => (
          <Therapist key={therapist.name} therapist={therapist}></Therapist>
        ))}
      </div>
    </div>
    </>
  );
};

export default Consultants;
