import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useActions } from "../Actions";
import LogoNew from "../assets/images/maulihospital.png";
import qrIcon from "../assets/qr-code.png";
import { store } from "../Store";

const Header = () => {
  const { state, dispatch } = useContext(store);
  const actions = useActions(state, dispatch);
  const history = useHistory();
  const { selectedTabName } = state;
  const [showHeaderBar, setHeaderBar] = useState(false);

  const onDrawerClick = () => {
    setHeaderBar(!showHeaderBar);
  };
  return (
    <div>
      <div className="md:hidden sm:hidden xs:hidden block flex bg-bg-blue text-white">
        <div className=" float-left">
          <img
            className="inline-block w-70 my-2 mr-10 lg:ml-30 ml-24 mr-16" /* ml-40 */
            src={LogoNew}
            alt={"Mauli Hospital Logo"}
          ></img>
        </div>
        <div className="float-left my-20 ">
          {/*  ml-50 */}
          <ul className="flex flex-row ">
            <li
              className={`text-12 mr-16 hover:cursor-pointer ${
                selectedTabName === "Home" ? "" : ""
              } blink2`}
            >
              <a
                className=""
                onClick={() => {
                  actions.seTabName("Home");
                  history.replace("/");
                }}
              >
                Blood Center: <br/>+91 9860022646 / 7020303318
              </a>
            </li>
            <li
              className={`text-12 mr-16 hover:cursor-pointer ${
                selectedTabName === "Home" ? "" : ""
              } blink`}
            >
              <a
                className=""
                onClick={() => {
                  actions.seTabName("Home");
                  history.replace("/");
                }}
              >
                Ambulance: <br/>+91 7888137887 / 7410141007
              </a>
            </li>
          </ul>
        </div>
        <div className="float-right my-20 ml-auto mr-16">
          {/* ml-50 */}
          <ul className="flex flex-row py-1">
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "Home" ? "font-bold" : ""
              }`}
            >
              <a
                className=""
                onClick={() => {
                  actions.seTabName("Home");
                  history.replace("/");
                }}
              >
                Home
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "aboutus" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("aboutus");
                  history.replace("/aboutus");
                }}
              >
                About Us
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "consultants" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("consultants");
                  history.replace("/consultants");
                }}
              >
                Consultants
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "doctors" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("doctors");
                  history.replace("/doctors");
                }}
              >
                Doctors
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "services" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("services");
                  history.replace("/services");
                }}
              >
                Services
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "schedule" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("schedule");
                  history.replace("/schedule");
                }}
              >
                Schedule
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "gallary" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("gallary");
                  history.replace("/gallary");
                }}
              >
                Gallery
              </a>
            </li>
            <li
              className={`text-16 lg:text-14 mr-16 hover:cursor-pointer ${
                selectedTabName === "contactus" ? "font-bold" : ""
              }`}
            >
              <a
                onClick={() => {
                  actions.seTabName("contactus");
                  history.replace("/contactus");
                }}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div className="loat-right mr-5 ml-0">
          <img
            className="inline-block my-16 w-40 h-40 invertImageColor hover:cursor-pointer" /* ml-40 */
            src={qrIcon}
            alt={"Mauli Hospital Review"}
            onClick={() =>
              window.open("https://g.page/r/CUM9tIkz9QbEEBM/review")
            }
          ></img>
        </div>
      </div>
      <div className="xs:flex md:flex sm:flex hidden flex bg-black text-white justify-evenly h-50">
        <div className="float-left mr-10">
          <img
            className="inline-block w-50 "
            src={LogoNew}
            alt={"Mauli Hospital Logo"}
          ></img>
        </div>
         <div
          className="float-left  top-10"
          stye={{ right: "0px", top: "10px" }}
        >
          <ul className="flex flex-row text-center my-auto mr-10">
            <li
              className={`text-8 hover:cursor-pointer  mr-10 ${
                selectedTabName === "Home" ? "" : ""
              } blink3`}
            >
              <a
                className=""
                onClick={() => {
                  actions.seTabName("Home");
                  history.replace("/");
                }}
              >
                Blood Center: <br/>+91 9860022646 /<br/> 7020303318
              </a>
            </li>
            <li
              className={`text-8 hover:cursor-pointer ${
                selectedTabName === "Home" ? "" : ""
              } blink4`}
            >
              <a
                className=""
                onClick={() => {
                  actions.seTabName("Home");
                  history.replace("/");
                }}
              >
                Ambulance: <br/>+91 7888137887 /<br/>  7410141007
              </a>
            </li>
          </ul>
        </div>
        <div
          className="float-ight my-12"
        >
          <button
            type="button"
            className="pt-p3 float-right bg-transparent hover:bg-teal-400 hover:text-gray-900 rounded-lg text-sm absolute top-2.5 left-2.5 inline-flex items-center justify-center dark:hover:bg-teal-400 dark:hover:text-white"
            onClick={() => {
              onDrawerClick();
            }}
          >
            <svg
              className=" w-6 h-6 text-white hover:text-white border-none"
              x-show="!showMenu"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          {showHeaderBar && (
            <div
              id="drawer-example"
              className=" mt-20 flex flex-col xs:w-60% sm:w-30% fixed top-0 right-0 z-40 p-4 overflow-y-auto transition-transform -translate-x-full bg-black dark:bg-gray-800"
              tabindex="-1"
              aria-labelledby="drawer-label"
            >
              <div className="float-right">
                <button
                  type="button"
                  className="p-2 float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm top-2.5 left-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {
                    onDrawerClick();
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="#ffffff"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
              <div className="">
                <ul className="flex flex-col">
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("Home");
                        history.replace("/");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("aboutUs");
                        history.replace("/aboutus");
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("consultants");
                        history.replace("/consultants");
                      }}
                    >
                      Consultants
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("doctors");
                        history.replace("/doctors");
                      }}
                    >
                      Doctors
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("services");
                        history.replace("/services");
                      }}
                    >
                      Services
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("schedule");
                        history.replace("/schedule");
                      }}
                    >
                      Schedule
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("gallary");
                        history.replace("/gallary");
                      }}
                    >
                      Gallery
                    </a>
                  </li>
                  <li className="text-14 mr-50 text-white ml-5">
                    <a
                      onClick={() => {
                        actions.seTabName("contactus");
                        history.replace("/contactus");
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
