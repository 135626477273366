import types from "./ActionTypes";
const initialState = {
  PorojectLoaded: true,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING_INDICATOR:
      return { ...state, ...{ isLoading: true } };
    case types.TOGGLE_SERVICE_POPUP:
      return { ...state, ...{ togglePopup: action.payload } };
    case types.SAVE_SELECTED_SERVICE:
      return { ...state, ...{ selectedServiceData: action.payload } };
    case types.SHOW_GALLARY_IMAGES:
      return { ...state, ...{ showImages: action.payload, showVideos: false } };
    case types.SHOW_GALLARY_VIDEOS:
      return { ...state, ...{ showVideos: action.payload, showImages: false } };
    case types.SET_SELECTED_TAB_NAME:
      return {
        ...state,
        ...{
          selectedTabName: action.payload,
          customerName: undefined,
          customerMobileNumber:undefined,
          customerEmail:undefined,
            customerMsg:undefined
        },
      };
    case types.SET_CUSTOMER_NAME:
      return { ...state, ...{ customerName: action.payload} };
    case types.SET_CUSTOMER_MOBILE_NUMBER:
      return { ...state, ...{ customerMobileNumber: action.payload } };
    case types.SET_CUSTOMER_EMAL:
      return { ...state, ...{ customerEmail: action.payload } };
    case types.SET_SCUSTOMER_MSG:
      return { ...state, ...{ customerMsg: action.payload} };
    default:
      return { state };
  }
};
export { initialState, reducer };
