import React , { useState,useContext } from "react";
import BackImg from "../assets/background-image.png";
import emailImg from "../assets/socialmedia/ic-email.png";
import YoutubeImg from "../assets/socialmedia/youtube.png";
import Facebook from "../assets/socialmedia/ic-facebook.png";
import Google from "../assets/socialmedia/ic-google1.png";
import Twitter from "../assets/socialmedia/ic-twitter.png";
import Instagram from "../assets/socialmedia/ic-instagram.png";
import qrcode from "../assets/socialmedia/qrcode.jpg";
const FORM_ENDPOINT = "https://herotofu.com/start";
import { store } from "../Store";
import { useActions } from "../Actions";
import Popup from "reactjs-popup";
import Close from "../assets/close.png";
import { useHistory } from "react-router-dom";
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const { state, dispatch } = useContext(store);
  const actions = useActions(state, dispatch);
  const { customerName, customerMobileNumber,customerEmail,customerMsg } = state;
  const [submitted, setSubmitted] = useState(false);
  const [togglePopup, settogglePopup] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

  function isValidEmail(email) {
    return emailRegex.test(email);
  }
  const isValidEmail2 =(e)=>{
    debugger
    if (!isValidEmail(e)) {
      setError('Email is invalid');
    } else{
      setError(null);
    }
  }
  
  const handleCloseClick = () => {
    settogglePopup(false);
    setSubmitted(false)
    history.replace("/contactus");
  };
  
    const sendEmail = (e) => {
      e.preventDefault(); 
  
      emailjs.sendForm('service_mauliHospital17', 'template_mauliHospital17', e.target, 'W-FPeMSgSR3GwN4Rn')
        .then((result) => {
          setSubmitted(true)
          settogglePopup(true)
        }, (error) => {
            console.log(error.text);
        });
    }


  const contentStyleForDesktopView = {
    padding: "0px",
    alignSelf: "center",
    width: "500px",
    height: "200px",
    borderRadius: "16px",
    'overflow-y':'auto'
  };

  const handleSubmit = (e) => {
  const { customerName, customerMobileNumber, customerEmail, customerMsg } = state;
  e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
let objectData = { customerName :customerName,
  customerMobileNumber : customerMobileNumber,
  customerEmail : customerEmail,
  customerMsg : customerMsg}
 

      emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', objectData, 'YOUR_USER_ID')
        .then((result) => {
            window.location.reload() 
        }, (error) => {
            console.log(error.text);
        });


    setSubmitted(true)
    settogglePopup(true)
  };
  
  return (
    <>
   <Helmet>
    <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
    <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
    </Helmet>
    <form className="contact-form" onSubmit={sendEmail}>
    <div className="w-full mx-auto my-auto items-center ">
      <div className="flex flex-row xs:flex-col sm:flex-col md:flex-col lg:flex-col w-full m-auto my-50"> 
      <div className="m-auto xs:w-70% sm:w-70% md:w-50% lg:w-50% w-50%">
      <div className="pt-0 mb-3">
        <input
          type="text"
          placeholder="Your Name"
          name="customerName"
          className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded doctors-accordian outline-none"
          onChange={() => {
            actions.setCusName(event.target.value);
          }}
          value={customerName}
          required
        />
      </div>
      <div className="pt-0 mb-3">
        <input
          type="number"
          placeholder="Your Mobile Number"
          name="customerMobileNumber"
          className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded doctors-accordian outline-none"
          onChange={()=>{actions.setCusMobileNumber(event.target.value);}}
          value={customerMobileNumber}
          required
          minLength={10}
          maxLength={10}
        />
        {customerMobileNumber && customerMobileNumber.length !=10 && <span className='mt-2 text-sm ml-2' style={{color: 'red'}}>Mobile number should be 10 digit only</span>}
      </div>
      <div className="pt-0 mb-3">
        <input
          type="email"
          placeholder="Your Email"
          name="customerEmail"
          className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded doctors-accordian outline-none"
          value={customerEmail}
          onChange={()=>{isValidEmail2(event.target.value);actions.setCusEmail(event.target.value);}}
          required
        />
         {error && <span className='mt-2 text-sm ml-2' style={{color: 'red'}}>{error}</span>}
      </div>
      <div className="pt-0 mb-3">
        <textarea
          placeholder="Your Message"
          name="customerMsg"
          className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded doctors-accordian outline-none"
          onChange={()=>{actions.setCusMsg(event.target.value);}}
          value={customerMsg}
          required
        />
      </div>
      <div className="pt-0 mb-3 text-center">
        <button
          className={`${!customerName || !customerMobileNumber || customerMobileNumber.length!=10 || !customerEmail||!customerMsg || error ? 'pointer-events-none opacity-25' :'cursor-pointer ' } w-80 active:bg-blue-600 hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none`}
          type="submit"
          disabled={!customerName || !customerMobileNumber || customerMobileNumber.length!=10 || !customerEmail||!customerMsg || error }
          >
          Send a message
        </button>
      </div>
    </div>
    <div className="xs:m-auto sm:m-auto md:m-auto lg:m-auto mr-auto xs:w-50% sm:w-50% md:w-50% lg:w-50%"> 
    <img
                src={qrcode}
                alt={qrcode}
                className="overflow-hidden w-full"
              />
             
        
      <div className="text-sm mt-5">Please use your mobile camera and scan above QR code <br/> to share your details of your own experience at Mauli Hospital </div>
      <div className="text-center text-md py-4">OR</div>
      <div className="pt-0 mb-3 text-center">
      <button
          className="text-center m-auto mt-10 w-80 active:bg-blue-600 hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none"
          type="submit"
        ><a target='_blank' alt='Mauli Hospital And Research Center Satara' href='https://g.page/r/CUM9tIkz9QbEEBM/review'>
          Review Us</a>
        </button>
      </div>
    </div>
      </div>
      {submitted&& 
       <Popup
       contentStyle={contentStyleForDesktopView}
       open={togglePopup}
       closeOnDocumentClick={false}
       closeOnEscape={false}
       repositionOnResize={false}
       lockScroll={true}
       modal
     >
         <div className="cursor-pointer cross mt-15 mr-15 m-10 w-20 h-20 float-right" id="cross-img">
           <img src={Close} onClick={()=>handleCloseClick()}/>
         </div>
         <div className="w-full my-50">
        <div className="text-center text-2xl ">Thank you!</div>
        <div className="text-center text-md">Hi, {customerName}, We'll be in touch soon.</div>
      </div>
     </Popup>
  }
    </div>
    </form>
    </>
  );
};
export default ContactUs;
