import React from "react";
import "./styles/style.css";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./component/header";
import Footer from "./component/footer";
import Home from "./component/home";
import AboutUs from "./component/aboutUs";
import Consultants from "./component/consultants";
import Doctors from "./component/doctors";
import Schedule from "./component/schedule";
import Services from "./component/services";
import Gallary from "./component/gallary";
import ContactUs from "./component/contactUs";
import { Config } from "./config";
import TaskManager from "react-gtm-module"
const TaskManagersArgs={
  gtmId:"G-0NJZ5RYB9L"
}

const App = () => {
  TaskManager.initialize(TaskManagersArgs);
  return (
    <React.Fragment key="route">
      <div>
        <Router>
          <Header />
          <main className="pt-5rem pb-8 mb-100 ">{/* mt-100 */}
            <Switch>
              <Route path="/aboutus">
                <AboutUs />
              </Route>
              <Route path="/consultants">
                <Consultants />
              </Route>
              <Route path="/doctors">
                <Doctors />
              </Route>
              <Route path="/services">
                <Services />
              </Route>
              <Route path="/schedule">
                <Schedule />
              </Route>
              <Route path="/gallary">
                <Gallary />
              </Route>
              <Route path="/contactus">
                <ContactUs />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </main>
          <Footer />
        </Router>
      </div>
    </React.Fragment>
  );
};
export default App;
