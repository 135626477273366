import React, { useState } from "react";
/* import PropTypes from "prop-types"; */
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Stepper = ({ images, activeIndex, onSelect }) => (
  <div className="mt-20 ">
    <ol className="relative list-none flex flex-row">
      {images.map((dot, index) => (
        <li
          key={index}
          style={{ width: 10, height: 10, marginRight: "4px" }}
          onClick={() => onSelect(index)}
          className={`inline-block rounded-full ${
            activeIndex === index ? "active bg-purple-800" : "bg-gray-300"
          }`}
        />
      ))}
    </ol>
  </div>
);

export const Carousel = ({ images }) => {
  let [activeStep, setActiveStep] = useState(0);

  return (
    <div className="flex flex-col justify-center items-center  w-full h-full">
      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={setActiveStep}
        enableMouseEvents
      >
        {images.map((img, i) => (
          <div key={i}>
            <div className="flex flex-row h-auto">
              {!img.onlyImage && <div
                className="ml-10 float-left font-normal mt-20 w-full text-text-desc xs:text-10 sm:text-10 md:text-10 text-md"
                dangerouslySetInnerHTML={{ __html: img.content }}
              ></div>}
              {!img.onlyImage && <div className="float-right">
                <img
                  key={img.alt}
                  /*  className="mx-auto border border-solid border-gray-600 rounded-2xl max-h-400" */
                  className="border border-gray-600 rounded-50% h-80 w-100% float-right"
                  src={img.src}
                  alt={img.alt}
                />
              </div>}
              {img.onlyImage && 
                <img
                  key={img.alt}
                  /*  className="mx-auto border border-solid border-gray-600 rounded-2xl max-h-400" */
                  className="border border-gray-600 h-450 w-100% float-right"
                  src={img.src}
                  alt={img.alt}
                />
              }
            </div>
            <div
              className="float-right font-normal text-14 "
              dangerouslySetInnerHTML={{ __html: img.footerText }}
            ></div>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Stepper
        images={images}
        activeIndex={activeStep}
        onSelect={setActiveStep}
      />
    </div>
  );
};

Carousel.propTypes = {};

Carousel.defaultProps = {};
