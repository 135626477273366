import types from './ActionTypes'

const middlewareErrorHanlder = (dispatch) => (error) => {
    console.log('error', error)
    dispatch({ type: types.API_ERROR, data: error })
    dispatch({ type: types.UNSET_LOADING_INDICATOR })
  }
const middleware = {}
let tokenHeaders;

middleware[types.GET_FIRST_API_CALL_REQUEST] = (dispatch) => (action) =>
  // API[action.type](action.payload)
  Promise.resolve(roleAccess)
    .then((data) => {
      dispatch({ type: types.UNSET_LOADING_INDICATOR })
      dispatch({ type: types.GET_FIRST_API_CALL_REQUEST_SUCCESS, data })
    })
    .catch(middlewareErrorHanlder(dispatch))

const applyMiddleware = (dispatch) => (action) => middleware[action.type] && middleware[action.type](dispatch)(action)

export { applyMiddleware }