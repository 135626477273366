import React from "react";

const Doctor = (props) => {
  const { name, image, designation,AvailableDay, AvailableTime,AppointmentFlag } = props && props.therapist;
  return (

                                <tbody>
                                    <tr>
                                        <td className="border p-5">
                                            <div className="drName text-left xs:ml-10 sm:ml-10 md:ml-10 ml-20">{name}</div>
                                            <div className="text-left xs:ml-10 sm:ml-10 md:ml-10 ml-20 ">{designation}</div>
                                        </td>
                                        <td className="border">
                                        <div className="drName text-left xs:ml-10 sm:ml-10 md:ml-10 ml-20"> {AvailableDay}</div>
                                        </td>
                                        <td className="border">
                                        <div className="drName text-left xs:ml-10 sm:ml-10 md:ml-10 ml-20"> {AvailableTime}</div>
                                        <div className="drName text-left xs:ml-10 sm:ml-10 md:ml-10 ml-20"> {AppointmentFlag? '(By appointment only)': ''}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            
  );
};

export default Doctor;
