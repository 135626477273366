import types from "./ActionTypes";

const useActions = (state, dispatch) => ({
  setEmailId: (data) => {
    dispatch({ type: types.SET_PROJECT_NAME, payload: "MyProject" });
  },
  toggleServicePopUp: (data) => {
    dispatch({
      type: types.TOGGLE_SERVICE_POPUP,
      payload: data,
    });
  },
  saveSelectedService: (data) => {
    dispatch({
      type: types.SAVE_SELECTED_SERVICE,
      payload: data,
    });
  },
  showImages: (data) => {
    dispatch({
      type: types.SHOW_GALLARY_IMAGES,
      payload: data,
    });
  },
  showVideos: (data) => {
    dispatch({
      type: types.SHOW_GALLARY_VIDEOS,
      payload: data,
    });
  },
  seTabName: (data) => {
    dispatch({
      type: types.SET_SELECTED_TAB_NAME,
      payload: data,
    });
  },
  setCusName: (data) => {
    dispatch({
      type: types.SET_CUSTOMER_NAME,
      payload: data,
    });
  },
  setCusMobileNumber: (data) => {
    dispatch({
      type: types.SET_CUSTOMER_MOBILE_NUMBER,
      payload: data,
    });
  },
  setCusEmail: (data) => {
    dispatch({
      type: types.SET_CUSTOMER_EMAL,
      payload: data,
    });
  },
  setCusMsg: (data) => {
    dispatch({
      type: types.SET_SCUSTOMER_MSG,
      payload: data,
    });
  },
});
export { useActions };
