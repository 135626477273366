import React, {useRef,useState}from "react";
import MauliHospitallPhoto from "../assets/mauli-hospital-hover.jpg";
import ReactPlayer from "react-player";
import MauliVideo2 from "../assets/videos/MauliVideo2.mp4";
import { useHistory } from "react-router-dom";
import { Collapse } from "react-collapse";
import openArrow from "../assets/open-arrow.png";
import closeArrow from "../assets/close-arrow.png";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const playerRef = useRef(null);
  const history = useHistory();
  const [relatedServicesFlag, setRelatedServiceFlag] = useState(false);
  const [rightsFlag, setrightsFlag] = useState(false);

let relatedServices = [
  {
    relatedServicesFlag: relatedServicesFlag,
    functionName: setRelatedServiceFlag,
    name: "Related Services",
    text: `<div class='flex flex-row xs:flex-col sm:flex-col lg:flex-col xl:flex-col text-white justify-evenly'>
    <ul class='ml-50 xs:ml-0 sm:ml-0 lg:ml-0 xl:ml-0 float-left text-20 xs:text-12 sm:text-12 lg:text-12 xl:text-12 w-40% xs:w-120% sm:w-120% lg:w-120% xl:w-120% review_bottom'>
    <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left'>Related Services</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Outpatient Services</div> <div class='text-left w-50%'>बाह्यरुग्ण सेवा</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Inpatient Services</div> <div class='text-left w-50%'>आंतररुग्ण सेवा</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Intensive Care Services</div> <div class='text-left w-50%'>अतिदक्षता विभाग</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Nursing</div> <div class='text-left w-50%'>नर्सिंग</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Housekeeping</div> <div class='text-left w-50%'>हाउसकीपिंग </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Security</div> <div class='text-left w-50%'>सिक्युरीटी </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Canteen</div> <div class='text-left w-50%'>कॅन्टीन </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Laundry</div> <div class='text-left w-50%'>लॉन्ड्री</div></div></li>
<br/>
   <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left'> Managerial Services</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Human Resource And Administration</div> <div class='text-left w-50%'>मानव संसाधन व प्रशासन</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Material Management</div> <div class='text-left w-50%'>मटेरियल मॅनेजमेंट</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Marketing And TPA Claims</div> <div class='text-left w-50%'>मार्केटिंग व टीपीए क्लेम्स</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Accounts And Finance </div> <div class='text-left w-50%'>अकाउंट्स फायनान्स</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Quality Management </div> <div class='text-left w-50%'> वालिटी मॅनेजमेंट </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>IT Department</div> <div class='text-left w-50%'>आय. टी. डिपार्टमेंट </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'> Medical Record Department </div> <div class='text-left w-50%'>मेडिकल रेकॉर्ड डिपार्टमेंट  </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Biomedical Engineering</div> <div class='text-left w-50%'>बायोमेडिकल इंजिनियरिंग</div></div></li>
<br/>
 <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left'> Speciality Services</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>General Medicine</div> <div class='text-left w-50%'>जनरल मेडिसीन</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>General Surgery</div> <div class='text-left w-50%'>जनरल सर्जरी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Obstetrics And Gynecology</div> <div class='text-left w-50%'>अब्स्टेट्रिक्स आणि गायनेकॉलॉजी </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>ENT</div> <div class='text-left w-50%'>कान नाक घसा</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Ophthalmology</div> <div class='text-left w-50%'>नेत्ररोग</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Super specility</div> <div class='text-left w-50%'>सुपर स्पेशालिटी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Orthopedics</div> <div class='text-left w-50%'>ऑर्थोपेडिक्स</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Radiology</div> <div class='text-left w-50%'>रेडिओलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Intensive Therapy</div> <div class='text-left w-50%'>इंटेन्सिव्ह घेरपी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Pediatrics</div> <div class='text-left w-50%'>पेडियाट्रीक्स</div></div></li>

<br/>
 <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left'>Supportive Services</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Anaesthesiology</div> <div class='text-left w-50%'>अनस्थेशियोलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Pharmacy</div> <div class='text-left w-50%'>फार्मसी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Physiotherapy</div> <div class='text-left w-50%'>फिजियोथेरेपी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Dietetics</div> <div class='text-left w-50%'>डायटेटिक्स</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Critical Care</div> <div class='text-left w-50%'>क्रिटिकल केअर</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>MRD (Medical Record Room)</div> <div class='text-left w-50%'>मेडिकल रेकॉर्ड रूम</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>TPA</div> <div class='text-left w-50%'>टी.पी.ए.</div></div></li>


  </ul><div className="vl"></div>
  <ul class='ml-50 xs:ml-0 sm:ml-0 lg:ml-0 xl:ml-0 float-right text-20 xs:text-12 sm:text-12 lg:text-12 xl:text-12 w-40% xs:w-120% sm:w-120% lg:w-120% xl:w-120% review_bottom'>
  <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left' >24 Hour Services</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Emergency</div> <div class='text-left w-50%'>इमर्जन्सी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Ambulance</div> <div class='text-left w-50%'>अॅम्बुलन्स</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Operation Theatre Services</div> <div class='text-left w-50%'>ऑपरेशन थिएटर सुविधा</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Radiology</div> <div class='text-left w-50%'>रेडिओलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Front Office </div><div class='text-left w-50%'>फ्रंट ऑफिस</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Facility Maintenance & Management</div> <div class='text-left w-50%'>फॅसिलिटी मेंटेनन्स अँड मॅनेजमेट</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Security</div> <div class='text-left w-50%'>सिक्युरिटी</div></div></li>
  <br/>
  <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left'> Service Exclusion</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>MRI</div> <div class='text-left w-50%'>एम.आर.आय.</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Mammography</div> <div class='text-left w-50%'>मॅमोग्राफी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Medical Oncology</div> <div class='text-left w-50%'>मेडिकल ऑन्कॉलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Radial Oncology </div> <div class='text-left w-50%'>रेडियल ऑन्कॉलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Pet Scan</div> <div class='text-left w-50%'>पेट स्कॅन</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Frozen Section</div> <div class='text-left w-50%'>फ्रोजन सेक्शन</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Bone Marrow</div> <div class='text-left w-50%'>बोन मॅरो </div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Angliography</div> <div class='text-left w-50%'>अॅन्जिओग्राफी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>2d Eco</div> <div class='text-left w-50%'>२ डी इको</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Doppler Studies </div> <div class='text-left w-50%'>डॉपलर स्टडीज्</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Clinical Laboratory- biochemistry</div> <div class='text-left w-50%'>क्लीनीकल लॅबोरेटरी- बायोकेमिस्ट्री,</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Microbiology</div> <div class='text-left w-50%'>मायक्रोबायोलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Pathology</div> <div class='text-left w-50%'>पॅथॉलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Radiology And Imaging</div> <div class='text-left w-50%'>रेडिओलॉजी अँड इमेजिंग डिजिटल एक्स-रे</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Digital X-ray</div> <div class='text-left w-50%'>अल्ट्रासाउंड</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Ultrasound, 2d Echo</div> <div class='text-left w-50%'>२ डी इको</div></div></li>
   <br/>
   <li class ='text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left'> Super Speciality Services</li><br/><br/>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Cardiology</div> <div class='text-left w-50%'>कार्डिओलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Urology</div> <div class='text-left w-50%'>यूरोलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Nephrology</div> <div class='text-left w-50%'>नेफ्रोलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Neurology</div> <div class='text-left w-50%'>न्यूरोलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Surgical Oncology</div> <div class='text-left w-50%'>सर्जिकल ऑन्कॉलॉजी</div></div></li>
  <li class='list-disc'><div class='flex flex-row mr-10'><div class='text-left w-50%'>Plastic Surgery</div> <div class='text-left w-50%'>प्लास्टिक सर्जरी</div></li>
 
  </ul>`,
  },
];
  let rightsAnsResponsibility = [
    {
      rightsFlag: rightsFlag,
      functionName: setrightsFlag,
      name: "Patients Rights And Responsibility",
      text: `<div class='flex flex-col text-white justify-evenly'>
    <ul class='p-20 xs:p-0 sm:p-0 lg:p-0 xl:p-0 float-left text-20 xs:text-12 sm:text-12 lg:text-12 xl:text-12 w-full'>
      <li class='flex flex-row text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left w-full'><div class='xs:text-14 sm:text-14 lg:text-14 xl:text-14 text-left w-50% m-auto'>PATIENTS RIGHTS</div> <div class='xs:text-14 sm:text-14 lg:text-14 xl:text-14  text-left w-50%'>रुग्णांचे हक्क</div></li><br/><br/><br class='xs:block hidden' />
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to information on diagnosis, treatment and medicines.</div> <div class='text-left w-50%'>रोगनिदान, उपचार आणि औषधांविषयी माहितीचा अधिकार.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to obtain all the relevant information about the professionals involved in the patient care.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'>रुग्णांची काळजी घेत असलेल्या सर्व व्यावसायिकांबाबत आवश्यक ती माहिती जाणून घेणे अधिकार.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to expect that all the communications and records pertaining to his/her case to be treated as confidential.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'>रुग्णांच्या बाबतीत सर्व संभाषण आणि नोंदी गोपनीय ठेवण्याची अपेक्षा व्यक्त करण्याचा अधिकार. </div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to every consideration of his/her privacy concerning his/her medical care programme.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'>रुग्णांच्या वैद्यकीय बाबीमधील प्रत्येक तपशील गोपनीय राखण्याचा अधिकार.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to expect prompt treatment in an emergency.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'> आणीबाणीच्या काळामध्ये तातडीने उपचार घेण्यासंदर्भात सहभागी न होण्याचा अधिकार.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to refuse to participate in human experimentation, research, project, affecting his/her care of treatment. Right to get copies of medical records.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'> वैद्यकीय नोंदीची प्रत मिळवण्याचा अधिकार.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to know what Hospital rules and regulations apply to him/her as a patient and the facilities available to the patient.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'>रुग्णाला लागू होणारे रुग्णालयाचे कायदे व नियम तसेच मिळणाऱ्या सोयी सुविधा माहित करून घेण्याचा अधिकार. </div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to get details of the bill.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'>बिलाचा तपशील घेण्याचा अधिकार.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Right to see second opinion about his her disease, treatment etc.</div> <div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0'></div></div></li>
    </ul><br/><div className="vl"></div>
      <ul class='p-20 xs:p-0 sm:p-0 lg:p-0 xl:p-0 float-right text-20 xs:text-12 sm:text-12 lg:text-12 xl:text-12 w-full review_bottom'>
      <li class='flex flex-row text-32 xs:text-16 sm:text-16 lg:text-16 xl:text-16 float-left w-full'><div class='xs:text-14 sm:text-14 lg:text-14 xl:text-14 text-left w-50% m-auto'>PATIENTS RESPONSIBILITIES</div> <div class='xs:text-14 sm:text-14 lg:text-14 xl:text-14 text-left w-50%'>रुग्णांच्या जबाबदान्या</div></li><br/><br/> <br class='xs:block hidden' />
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To follow the doctors instructions deligently.</div> <div class='text-left w-50%'>निश्चित केलेले उपचार विश्वासाने करून घेणे व रुग्णालयात शांतता ठेवणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To faithfully under the agreed therapy.</div> <div class='text-left w-50%'>डॉक्टरांच्या सूचनांचे योग्य रीतीने पालन करणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To follow the doctors instructions deligently.</div> <div class='text-left w-50%'>संसर्गजन्य रोगांच्या बाबतीत डॉक्टरांच्या सल्ल्याने आवश्यक ते प्रतिबंधात्मक उपाय करणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To take necessary preventive measures in case of infectious disease as per doctors instructions.</div> <div class='text-left w-50%'>डॉक्टर व नर्स मनुष्य असल्याने त्यांच्याकडून एखादी चूक होण्याची शक्यता असल्याची जाणीव असणे रुग्णालयीन कागदपत्रात फेरबदल किंवा खाडाखोड न करणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To be aware that doctors and nurses are also human being and have limitation.</div><div class='text-left w-50%'>डॉक्टर व रुग्णालयाचे जिये अपेक्षित आहे तेथे बिल तातडीने चुकते करणे. डॉक्टर व नर्स यांच्या स्वायत्तेचे महत्त्व जाणणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To make payment for the treatment whenever applicable to the doctor/ Hospital promptly.</div> <div class='text-left w-50%'>डॉक्टर व नर्स यांचा आदर करणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>Tourist spot the autonomy of the doctor and nurses. To treat doctor and nurses with respect.</div> <div class='text-left w-50%'>दवाखाना, रुग्णालय, क्लिनिकमध्ये उपचारांसाठी दिलेल्या वेळेवर उपस्थित राहणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To be punctual to attend the clinics/hospital for treatment at the given time.</div> <div class='text-left w-50%'>आपल्या आजाराबाबतीचे सर्व नोंदी जपून ठेवणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To preserve all the records of once illness.</div> <div class='text-left w-50%'>रुग्णालय व डॉक्टर बदलायचे असल्यास डॉक्टरांना तशी कल्पना देणे.</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To keep the doctor informed if the patient wants to change Hospital/doctor.</div> <div class='text-left w-50%'> रुग्णालयामध्ये मद्यपान, धूम्रपान, तंबाखू सेवन करण्यास सक्त मनाई आहे</div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To avoid any alteration in medical record.</div> <div class='text-left w-50%'></div></div></li>
      <li class='list-disc'><div class='flex flex-row'><div class='text-left w-50% m-auto xs:m-0 sm:m-0 lg:m-0 xl:m-0 mr-10'>To avoid any wrong information or producing wrong documentation.</div> <div class='text-left w-50%'></div></li>
      </ul>`,
    },
  ];
  return (
    <>
    <Helmet>
        <title>Mauli Hospital And Research Center Satara | Mauli Hospital, Degaon Phata, Satara</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="keywords" content="mauli, hospital, satara,Mauli Hospital And Research Center, Mauli Hospital Satara, Degaon Phata Mauli Hospital, Best Hospital in Satara, Dr. Gauri Jadhav, Dr. Eknath Jadhav, Piles Hospital, Piles Hospital in Satara"/>
        <meta name="description" data-rh="true" content="mauli, hospital, satara,Mauli Hospital And Research Center Satara, in the past 10 years has set new bench marks for standards in the health care industry and is marked as one of the top hospitals in Satara(Maharashtra)."/>
      </Helmet>
    <div>
      <div className="w-80 mx-auto xs:flex-col sm:flex-col md:flex-col lg:flex-col flex flex-cols items-center mt-20 justify-between">
        <div className="xl:w-40% 2xl:w-40% py-5 md:px-5 px-3 xl:ml-20 2xl:ml-20">
          <h3 className="title text-16 text-hyperlink  leadin-27 font-normal mb-10">
            Who we are
          </h3>

          <h1 className="subtitle xs:text-20 sm:text-20 md:text-20 lg:text-20 text-40 text-black leading-27 font-bold">
            Welcome to <br />
            Mauli Hospital and Research Centre, Satara.
          </h1>
          <p className="text-text-desc xs:text-sm sm:text-sm md:text-sm lg:text-sm text-md lg:mt-8 mt-3 text-justify">
            Established over 10 years ago, also, {" "}
            <span className="text-red">NABH approved center</span> which offers
            a broad range of diagnostic, therapy and counseling services for
            adults, families and children.<br/><br/>
            We have over 25+ doctors, Gynaecologist, Pediatrician, ENT
            Specialists and social workers available to provide a wide variety
            of services and meet your specific needs and requirements. Our
            hospital provides services 24/7. Also, we have well managed staff, cleaning
            staff, nursing staff, medical store. For emergency cases we are
            providing <span className="text-red">Ambulence 24/7</span>.<br/><br/>
            For over the years, Mauli Hospital And Research Center in
            Satara has been committed as a premier hospital to those who seek
            its exceptional care in Satara and surrounding regions. Starting with only OPD then 10 beded surgical hospital and now 32 beded multispecility hospital in the heart
            of Satara, the hospital has pioneered medical frontiers and proudly
            serves as a 50-bedded multi-speciality hospital centre, all located
            in Satara, under the exceptional stewardship of
            <a
              className="underline"
              onClick={() => history.replace("/consultants")}>
              <span className="text-green text-20 font-bold hover:cursor-pointer">
                {" "}
                Dr. Eknath Jadhav{" "}
              </span>
            </a>
            and
            <a
              className="underline"
              onClick={() => history.replace("/consultants")}>
              <span className="text-green text-20 font-bold hover:cursor-pointer">
                {" "}
                Dr. Gauri Jadhav 
              </span>
            </a>
            &nbsp;a renowned figure in Satara's medical history.
          </p>
        </div>
        <div className="xl:w-40% 2xl:w-40% py-5 zoom xl:mr-40 2xl:mr-40 xs:px-5 sm:px-5 md:px-5 xs:py-5 sm:py-5 md:py-5">
          <div className=" w-100% h-100%">
            <ReactPlayer
              className="videoFrame w-100% h-100% object-cover "
              ref={playerRef}
              url={MauliVideo2}
              controls
              onFullscreenMode
              /*onMouseOver={(event) => event.target.play()}
          onMouseOut={(event) => event.target.pause()}*/
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
          <h1 className="subtitle text-20 text-black leading-27 font-bold text-center mt-10">
            Mauli Hospital and Research Centre, Satara.
          </h1>
        </div>
      </div>
      <div className="grid grid-cols-1 w-80 mx-auto items-center mt-20 justify-between">
        {relatedServices &&
          relatedServices.map((data, index) => (
            <div className={`${data.className} mr-10 my-10`}>
              <div
                className=" text-center p-5 m-3 book-item overflow-hidden"
                onClick={() => data.functionName(!data.relatedServicesFlag)}
              >
                <div className="overflow-hidden flex flex-row hover:cursor-pointer">
                  <div className="mr-5 w-full font-bold text-left xs:text-16 sm:text-16 md:text-16 lg:text-16 text-16">
                    {data.name}
                  </div>
                  <img
                    className="w-18 h-18 my-auto"
                    src={data.relatedServicesFlag ? openArrow : closeArrow}
                  />
                </div>
                <Collapse isOpened={data.relatedServicesFlag}>
                  <div className=" text-center p-5 m-3 bg-bg-green">
                    <div className=" text-center p-5 m-3 ">
                      <p
                        className="py-1 text-16 font-serif"
                        dangerouslySetInnerHTML={{ __html: data.text }}
                      ></p>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          ))}
      </div>
      <div className="grid grid-cols-1 w-80 mx-auto items-center justify-between">
        {rightsAnsResponsibility &&
          rightsAnsResponsibility.map((data, index) => (
            <div className={`${data.className} mr-10`}>
              <div
                className=" text-center p-5 m-3 book-item overflow-hidden"
                onClick={() => data.functionName(!data.rightsFlag)}
              >
                <div className="overflow-hidden flex flex-row hover:cursor-pointer">
                  <div className="mr-5 w-full font-bold text-left xs:text-16 sm:text-16 md:text-16 lg:text-16 text-16">
                    {data.name}
                  </div>
                  <img
                    className="w-18 h-18 my-auto"
                    src={data.rightsFlag ? openArrow : closeArrow}
                  />
                </div>
                <Collapse isOpened={data.rightsFlag}>
                  <div className=" text-center p-5 m-3 bg-bg-green">
                    <div className=" text-center p-5 m-3 ">
                      <p
                        className="py-1 text-16 font-serif"
                        dangerouslySetInnerHTML={{ __html: data.text }}
                      ></p>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          ))}
      </div>
    </div>
    </>
  );
};
export default AboutUs;
