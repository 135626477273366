import React, { useRef } from "react";
import ReactPlayer from "react-player";

const Videos = (props) => {
  const playerRef = useRef(null);
  const { name, video } = props && props.videos;
  return (
    <div className=" text-center p-5 m-3 overflow-hidden flex flex-col">
      <div className=" w-100% h-120">
        <ReactPlayer
          className="videoFrame w-100% h-140% object-cover "
          ref={playerRef}
          url={video}
          controls
          onFullscreenMode
          /*onMouseOver={(event) => event.target.play()}
  onMouseOut={(event) => event.target.pause()} */
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
        />
      </div>
      <div className="text-center font-bold mt-150 mb-2 text-black">{name}</div>
    </div>
  );
};

export default Videos;
